<template>
  <LayoutDefault>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
      />
      <Loading :active="loading" />
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Puntos Colombia</h1>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-sm-6">
              <input
                type="file"
                id="fileA"
                ref="fileA"
                @change="handleFileUpload('accumulated')"
                style="display: none"
              />
              <button
                type="button"
                class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
                @click="$refs.fileA.click()"
              >
                Importar Acumulados
              </button>
              <input
                type="file"
                id="fileR"
                ref="fileR"
                @change="handleFileUpload('reedemed')"
                style="display: none"
              />
              <button
                type="button"
                class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
                @click="$refs.fileR.click()"
              >
                Importar Redimidos
              </button>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->

      <section class="content">
        <div class="container-fluid">
          <div class="card card--table">
            <!-- <div class="card-header">
      
            </div> -->
            <!-- /.card-header -->
            <div class="card-body">
              <table
                id="example1"
                class="table table-striped table-bordered table-sm"
              >
                <thead>
                  <tr>
                    <th>Últimas Importaciones</th>
                    <th>Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(point, index) in points" :key="index">
                    <td>{{ new Date(point.created_at).toLocaleString() }}</td>
                    <td>{{ point.type }}</td>
                  </tr>
                  <tr v-if="points.length === 0">
                    <td>No se encontraron logs disponibles</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Últimas Importaciones</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import pointService from "@/services/pointService";
import Toast from "../components/Toast";
import Loading from "../components/Loading";

export default {
  name: `Points`,
  data() {
    return {
      file: "",
      points: [],
      toastType: "",
      toastActive: false,
      toastMessage: "",
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.getLogPoints(); // 1
    setTimeout(() => {
      this.loading = false;
    });
  },
  methods: {
    async getLogPoints() {
      const res = await pointService.getAllLogPoints();
      if (res && res.response) {
        this.points = res.response;
        if (res.response.data) {
          this.points = res.response.data;
        }
      }
    }, // 1
    async submitFile(type) {
      this.loading = true;
      const formData = new FormData();
      formData.append("file", this.file);
      console.log(">> formData >> ", formData);
      const res = await pointService.uploadFile(formData, type);
      if (res.error || (res.code && res.code !== 200)) {
        this.resetInputFile();
        this.toastType = "error";
        this.toastMessage = "Fallo al cargar";
        this.toastActive = true;
        return;
      }
      this.toastType = "success";
      this.toastMessage = "Cargado correctamente";
      this.toastActive = true;
      console.log(res);
      this.resetInputFile();
      await this.getLogPoints();
      this.loading = false;
    },
    resetInputFile() {
      this.file = "";
      const input = this.$refs.fileR;
      input.type = "text";
      input.type = "file";
      input.value = "";
      const input2 = this.$refs.fileA;
      input2.type = "text";
      input2.type = "file";
      input2.value = "";
    },
    handleFileUpload(type) {
      if (type === "reedemed") {
        this.file = this.$refs.fileR.files[0];
      } else {
        this.file = this.$refs.fileA.files[0];
      }
      console.log(">>>> 1st element in files array >>>> ", this.file);
      this.submitFile(type);
    },
  },
  components: {
    LayoutDefault,
    Toast,
    Loading,
  },
};
</script>