import config from '@/config/config';
import Axios from 'axios';

export default new class PointService {
    private config = config.getConfig();
    getToken() {
        return localStorage.getItem('__token');
    }

    async uploadFile(formData: any, type: string) {
        // You should have a server side REST API 
        try {
            return (await Axios.post(`${this.config.apiUrl}/points/${type}`,
                formData, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            })).data;
        } catch (e) {
            let error = e.response
            if (error.data) {
                error = error.data
            }
            return error;
        }
    }
    async getAllLogPoints() {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/points`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
};

